import React from 'react'
import { graphql } from 'gatsby'
import GraphQLErrorList from '../lib/graphql-error-list'
import InViewMonitor from 'react-inview-monitor'

import SEO from '../components/scaffolding/seo'
import Header from '../components/header'
import Footer from '../components/footer'

import Hero from '../modules/hero'
import { Module } from '../components/scaffolding/module'

export const query = graphql`
  query PageTemplateQuery($id: String!) {
    page: sanityPage(id: { eq: $id }) {
      id
      publishedAt
      title
      slug {
        current
      }
      _rawHero
      hero {
        bg_color
        bg_image {
          asset {
            url
          }
        }
        content {
          _key
          _type
        }
      }
      _rawContent(resolveReferences: { maxDepth: 6 })
      content {
        ... on SanityCallout {
          _key
          _type
        }
        ... on SanityTextFull {
          _key
          _type
          title
          width
        }
        ... on SanityTextSplit {
          _key
          _type
          title
          width
        }
        ... on SanityTextImage {
          _key
          _type
          title
          display
          isFlush
          image {
            asset {
              fluid(maxWidth: 2000) {
                ...GatsbySanityImageFluid
              }
            }
            alt
          }
        }
        ... on SanityForm {
          _key
          _type
          note
          submit
        }
        ... on SanityBanner {
          _key
          _type
          button {
            cta
            page {
              slug {
                current
              }
            }
            color
          }
        }
        ... on SanityResources {
          _key
          _type
          title
          items {
            title
            category
            description
            image {
              alt
              asset {
                fixed(width: 900, height: 900) {
                  src
                }
              }
            }
            cta
            download {
              asset {
                url
              }
            }
          }
        }
        ... on SanityVideos {
          _key
          _type
          title
          items {
            url
            title
            description
          }
        }
        ... on SanityTeam {
          _key
          _type
          title
          items {
            name
            title
            description
            headshot {
              alt
              asset {
                fixed(width: 1200, height: 1200) {
                  src
                }
              }
            }
          }
        }
        ... on SanityDivider {
          _key
          _type
          show
        }
      }
      seo {
        description
        share {
          asset {
            fixed(width: 1200, height: 630) {
              src
            }
          }
        }
      }
    }
  }
`

const PageTemplate = props => {
  const { data, errors } = props
  const page = data && data.page

  return (
    <>
      {errors ? (
        <SEO title="GraphQL Error" />
      ) : (
        <SEO
          title={page.title}
          description={page.seo ? page.seo.description : ''}
          shareGraphicURL={
            page.seo && page.seo.share ? page.seo.share.asset.fixed.src : null
          }
        />
      )}
      {errors && <GraphQLErrorList errors={errors} />}

      <Header active={page.slug.current} />

      <main role="main" id="content">
        {page.hero && <Hero hero={page.hero} content={page._rawHero.content} />}

        {page.content.map((module, key) => (
          <InViewMonitor
            key={key}
            classNameNotInView="v-hide"
            classNameInView="fade-in-up"
          >
            <Module page={page} module={module} />
          </InViewMonitor>
        ))}
      </main>

      <Footer />
    </>
  )
}

export default PageTemplate
